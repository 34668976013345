import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import Wrapper from './Wrapper';
import Image from './Image';
import { fontSize, fontFamily, color, spacing, mqUp } from '../../constants/styles';

const Container = styled.div`
  padding: .1rem 0 ${spacing.XXL} 0;
  position: relative;
`;

const BackgroundSkew = css`
  position: absolute;
  top: -10rem;
  left: 0;
  right: 0;
  bottom: -21.3vw;
  z-index: -1;
  transform: skewY(12deg);
  transform-origin: right;
`;

const Illustration = css`
  opacity: 0;
  max-width: 100%;
  transform: skewY(-12deg);
  @supports (mix-blend-mode: multiply) {
    mix-blend-mode: multiply;
    opacity: 0.08;
  }
`;

const Title = styled.h1`
  text-transform: uppercase;
  line-height: 1.25;
  letter-spacing: 1px;
  ${mqUp('smartphone')} {
    font-size: ${fontSize.L};
  }
  ${mqUp('tablet')} {
    font-size: ${fontSize.XXL};
  }
`;

const TitleLight = styled.div`
  font-family: ${fontFamily.secondaryLight};
  color: ${color.neutralDark};
`;

const TitleBold = styled.div`
  font-family: ${fontFamily.secondaryBold};
  font-size: 160%;
  color: ${color.light};
`;

const Hero = props => {
  return (
    <Container>
      <div
        css={[
          BackgroundSkew,
          css`
            background-image: linear-gradient(90deg, ${props.gradient.start} 0%, ${props.gradient.end} 100%);
          `
        ]}
      >
        <Wrapper css={css`
          display: flex;
          align-items: flex-start;
          justify-content: center;
          height: 100%;
          overflow: hidden;
        `}>
          <Image css={Illustration} data={{url: props.illustration, alt: ''}} />
        </Wrapper>
      </div>
      <Wrapper
        css={css`
          ${mqUp('tablet')} {
            margin-top: ${spacing.L};
            margin-bottom: ${spacing.L};
          }
          ${mqUp('desktop')} {
            margin-top: ${spacing.XXL};
            margin-bottom: ${spacing.XXL};
          }
        `}
      >
        <Title>
          <TitleLight>{props.titleLight} </TitleLight>
          <TitleBold>{props.titleBold}</TitleBold>
        </Title>
      </Wrapper>
    </Container>
  );
};

export default Hero;
