import React, { SFC } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import Image from '../base/Image';
import { Link } from 'gatsby';
import {
  mqUp,
  color,
  spacing,
  radius,
  fontFamily,
  fontSize,
} from '../../constants/styles';

interface MemberCardProps {
  member: any;
  index: number;
}

const Container = styled.div`
  position: relative;
  background: ${color.light};
  border-radius: ${radius.S};
  color: ${color.neutral};
  width: 100%;
  max-width: 66rem;
  margin: 0 auto;
  &::after {
    z-index: -1;
    content: '';
    display: block;
    position: absolute;
    top: ${spacing.M};
    left: ${spacing.M};
    right: ${spacing.M};
    bottom: 0;
    background: ${color.light};
    box-shadow: 0 0 3rem ${color.neutralDark};
    opacity: 0.15;
  }
`;

const Body = styled.div`
  padding: ${spacing.L} ${spacing.XL};
  height: 100%;
  ${mqUp(540)} {
    display: flex;
    align-items: center;
  }
`;


const ImageContainer = styled.div`
  position: relative;
  width: 12rem;
  margin: 0 auto;
  &:before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 8.74rem;
    background: currentColor;
  }
  ${mqUp(540)} {
    width: 33%;
    max-width: 17.5rem;
    flex-shrink: 0;
    margin: 0;
  }
`;

const ImageStyle = css`
  border-radius: 0 0 8.74rem 8.74rem;
  position: relative;
  margin-top: -1.2rem;
`;

const Text = css`
  text-align: center;
  margin-top: ${spacing.M};
  ${mqUp(540)} {
    text-align: left;
    margin-top: 0;
    margin-left: ${spacing.L};
  }
`;

const Name = css`
  font-family: ${fontFamily.secondaryBold};
  font-size: ${fontSize.L};
  color: ${color.neutralDark};
  letter-spacing: 0.04em;
`;

const Job = css`
  font-family: ${fontFamily.secondaryMedium};
  font-size: ${fontSize.XS};
  margin-top: ${spacing.XS};
`;

const Description = css`
  margin-top: ${spacing.S};
`;


const selectedColors = [4, 5, 2, 1, 3];

const ProjectCard: SFC<MemberCardProps> = ({
  member,
  index,
  ...rest
}) => {
  const colorIndex = selectedColors[index % selectedColors.length];
  return (
    <Container {...rest}>
      <Body>
        <ImageContainer css={css`color: ${color[`clr${colorIndex}Light`]};`}>
          <Image css={ImageStyle} data={member.picture} />
        </ImageContainer>
        <div css={Text}>
          <h2 css={Name}>{member.name}</h2>
          <div css={[Job, css`color: ${color[`clr${colorIndex}`]};`]}>{member.job_title}</div>
          <div css={Description} dangerouslySetInnerHTML={{ __html: member.description.html }}></div>
        </div>
      </Body>
    </Container>
  );
};

export default ProjectCard;
