import React from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import App from '../components/layout/App';
import Wrapper from '../components/base/Wrapper';
import MemberCard from '../components/base/MemberCard';
import Hero from '../components/base/Hero';
import { spacing, mqUp } from '../constants/styles';
import teamIllustration from '../images/illustrations/team.svg';

const Container = styled.div`
  padding: .1rem 0 ${spacing.XXL} 0;
  position: relative;
`;

const Grid = styled.div`
  display: grid;
  grid-gap: ${spacing.L};
  ${mqUp('tablet')} {
    grid-template-columns: repeat(1, 1fr);
  }
  ${mqUp('desktop')} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const TeamPage = props => {
  const DATA = props.data.team.data;
  return (
    <App>
      <Hero
        titleLight={DATA.title_light}
        titleBold={DATA.title_bold}
        gradient={{start: '#F54E81', end: '#5F54A2'}}
        illustration={teamIllustration}
      />
      <Container>
        <Wrapper
          css={css`
            margin-bottom: ${spacing.XXL};
          `}
        >
          <Grid>
            {DATA.members.map((member, index) => (
              <MemberCard
                key={member.name}
                member={member}
                index={index}
              />
            ))}
          </Grid>
        </Wrapper>
      </Container>
    </App>
  );
};

export default TeamPage;

export const teamQuery = graphql`
  query TeamQuery {
    team: prismicTeam {
      data {
        title_light
        title_bold
        members {
          name
          job_title
          description {
            html
          }
          picture {
            url
            alt
          }
        }
      }
    }
  }
`;
